import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { FaPlay, FaPause } from "react-icons/fa";

const Player = ({ track }) => {
  const [currentTrack, setCurrentTrack] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    if (isPlaying && currentTrack && currentTrack.id === track.id) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying, currentTrack, track]);

  const togglePlay = () => {
    setCurrentTrack(track);
    setIsPlaying(!isPlaying);
  };

  return (
    <Box>
      <audio ref={audioRef} src={track.preview_url}></audio>
      <Box onClick={togglePlay}>
        {isPlaying ? <FaPause style={{fontSize : "32px"}}/> : <FaPlay style={{fontSize : "32px"}} />}
      </Box>
    </Box>
  );
};

export default Player;
