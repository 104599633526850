import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { AuthProvider } from './context/Auth';
import { AppRoutes } from './router/AppRoutes';


function App() {
  return (
    <BrowserRouter>
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  </BrowserRouter>
  );
}

export default App;
