import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Typography } from "@mui/material";

import "../../../scss/main.css";

import { useLocation } from "react-router-dom";
import axios from "axios";
import ApiConfig from "../../../ApiConfig/ApiConfig";

const PrivacyPolicy = () => {
  const location = useLocation();
  const [data, setData] = useState("");

  //   const stripHtml = (html) => {
  //     let doc = new DOMParser().parseFromString(html, "text/html");
  //     return doc.body.textContent || "";
  //   };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios({
          method: "GET",
          url: `${ApiConfig.getStaticContentByType}?contentType=${location.state.contentType}`,
        });

        if (response && response?.data?.responseCode === 200) {
          let result = response.data.result.description;

          setData(result);
        }
      } catch (error) {
        console.error("An error occurred while fetching the data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <DashboardLayout
      sectionName="Static Content Management"
      title={location.state.title}
    >
      <Box className="StaticScreenContainer">
        <Box className="StaticScreenContent">
          <Typography component="div">
            <div dangerouslySetInnerHTML={{ __html: data }} />
          </Typography>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default PrivacyPolicy;
