import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, Tooltip } from '@mui/material';

import '../scss/main.css';

const PaginationTableStyle = {
  rowStyle: {
    backgroundColor: 'rgb(0, 168, 215)',
    color: 'white',
    flex: '1 1 auto',
    padding: 8,
  },
  
  tableContainer: {
    overflowX: 'auto',
  },

  tableheaderCell : {
    color: 'white',
    fontSize: '16px',
    fontWeight: 500,
  },

  tableCell: {
    flex: '1 1 auto',
    padding: 8,
  },
}

const PaginationTable = ({ columns, data, rowsPerPageOptions }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const shouldShowPagination = data.length > rowsPerPageOptions[0];

  return (
    <Paper>
      <TableContainer className="scrollableTableContainer" sx={PaginationTableStyle.tableContainer}>
        <Table>
          <TableHead>
            <TableRow sx={PaginationTableStyle.rowStyle}>
              {columns.map((column) => (
                <TableCell key={column.id} style={PaginationTableStyle.tableheaderCell}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((column) => {
                  const cellData = row[column.id];
                  return (
                    <TableCell key={column.id} style={PaginationTableStyle.tableCell}>
                      {cellData.length > 28 ? (
                        <Tooltip title={cellData} arrow>
                          <span>{cellData.slice(0, 28)}...</span>
                        </Tooltip>
                      ) : (
                        cellData
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {shouldShowPagination && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

PaginationTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

PaginationTable.defaultProps = {
  rowsPerPageOptions: [10, 25],
};

export default PaginationTable;
