import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Typography } from "@mui/material";
import typographyStyles from '../../../theme/Typography';
import BasicButtons from "../../../components/Button";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../../../scss/main.css';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiConfig from "../../../ApiConfig/ApiConfig";


const EditPrivacyPolicy = () => {
    const location=useLocation();
    const [editorContent, setEditorContent] = useState(location.state.description);

    const Navigate = useNavigate();


   
    const [data,setData]=useState('')
    

    const fetchData = async () => {
        try {
            const response = await axios({
                method: "PUT",
                url:`${ApiConfig.updateStaticContent}`,
                header:{
                    token:sessionStorage.getItem('token')
                },
                data:{
                    staticContentId:location.state._id,
                    contentType:location.state.contentType,
                    title:location.state.title,
                    description:editorContent
                }
               
            });
          
            if (response && response?.data?.responseCode === 200) {
                Navigate(-1);
            }
        } catch (error) {
            console.error('An error occurred while fetching the data:', error);
        }
    };

    return(
        <DashboardLayout sectionName="Static Content Management" title={location.state.title}>
            <Box className="StaticScreenContainer">
                <Box className="StaticEditorContainer">
                <CKEditor
                        editor={ClassicEditor}
                        data={editorContent}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setEditorContent(data);
                        }}
                        config={{
                            toolbar: [
                                'heading', '|',
                                'bold', 'italic', 'underline', 'strikethrough', '|',
                                'bulletedList', 'numberedList', 'blockQuote', '|',
                                'link', 'imageUpload', 'insertTable', 'mediaEmbed', '|',
                                'undo', 'redo'
                            ],
                            height: '400px',
                        }}
                        className="StaticEditor"
                    />
                </Box>
                <Box className="StaticBtnContainer">
                            <BasicButtons text="Update"  onClick={() => fetchData()}/>
                            <BasicButtons text="Back" onClick={() => {Navigate("/staticcontent")}}/>
                        </Box>
            </Box>
        </DashboardLayout>
    );
}

export default EditPrivacyPolicy;