import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import BasicButtons from "../components/Button";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/Auth";

const logoutModalStyle = {
  logoutContainer: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 25%",
    background: "rgba(0, 0, 0, 0.5)",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1000,
  },

  logoutContent: {
    padding: "30px",
    width: "100%",
    maxWidth: "500px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",

    background: "white",
  },

  logoutTextSection: {
    // border: '2px solid orange',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },

  logoutButtonSection: {
    // border: '2px solid yellow',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "2%",
    width: "100%",
  },

  logoutIcon: {
    fontSize: "100px",
    color: "rgb(0, 152, 206)",
  },

  logoutTitle: {
    fontSize: "32px",
    fontWeight: "700",
    fontFamily: "'Mulish', sans-serif",
  },

  logoutText: {
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "'Mulish', sans-serif",
  },
};

const LogoutModal = ({ onClose }) => {
  const Navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  return (
    <Box sx={logoutModalStyle.logoutContainer}>
      <Box sx={logoutModalStyle.logoutContent}>
        <Box>
          <PowerSettingsNewIcon sx={logoutModalStyle.logoutIcon} />
        </Box>
        <Box sx={logoutModalStyle.logoutTextSection}>
          <Typography sx={logoutModalStyle.logoutTitle}>Logout?</Typography>
          <Typography sx={logoutModalStyle.logoutText}>
            Are you sure you want to logout ?
          </Typography>
        </Box>
        <Box sx={logoutModalStyle.logoutButtonSection}>
          <BasicButtons text="Cancel" onClick={onClose} />
          <BasicButtons
            text="Yes"
            onClick={() => {
              logout();
              Navigate("/", { replace: true });
              sessionStorage.removeItem("userName");
              sessionStorage.removeItem("profileImage");
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LogoutModal;
