import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, Box, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import '../scss/main.css';

const PaginationTableStyle = {
  rowStyle: {
    backgroundColor: 'rgb(0, 168, 215)',
    color: 'white',
    flex: '1 1 auto',
    padding: 8,
  },
  
  tableContainer: {
    overflowX: 'auto',
  },

  tableheaderCell : {
    color: 'white',
    fontSize: '16px',
    fontWeight: 500,
  },

  tableCell: {
    // border: '2px solid pink',
    // minWidth: 200, 
    flex: '1 1 auto',
    padding: 8,
  },
}

const PaginationTable = ({ columns, data, rowsPerPageOptions }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navigate = useNavigate();

  const handleView = (endPoint,row) => {
    // const {title,contentType}=row
  
    navigate(endPoint,{state:row});
  };

  const handleEdit = (endPoint,row) => {
      navigate(endPoint,{state:row});
  };

 
  return (
    <Paper>
      <TableContainer className="scrollableTableContainer" sx={PaginationTableStyle.tableContainer}>
        <Table>
          <TableHead>
            <TableRow sx={PaginationTableStyle.rowStyle}>
              {columns.map((column) => (
                // <TableCell key={column.id}>{column.label}</TableCell>
                <TableCell key={column.id} style={PaginationTableStyle.tableheaderCell}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
             
              <TableRow key={rowIndex}>
                
                {columns.map((column) => (
                  <TableCell key={column.id} style={PaginationTableStyle.tableCell}>
                  {column.id === 'action' ? (
                    <Box className="row-button-container">
                      <Button
                        variant="outlined"
                        onClick={() => handleView(row.viewEndpoint,row)}
                        sx={{ color: 'rgb(0, 152, 206)', borderColor: 'rgb(0, 152, 206)', fontFamily: "'Mulish', sans-serif" }}
                      >
                        View
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => handleEdit(row.editEndpoint,row)}
                        sx={{ color: 'rgb(0, 152, 206)', borderColor: 'rgb(0, 152, 206)', fontFamily: "'Mulish', sans-serif" }}
                      >
                        Edit
                      </Button>
                    </Box>
                  ) : (
                    row[column.id]
                  )}
                </TableCell>
                  // <TableCell key={column.id} style={PaginationTableStyle.tableCell}>
                  //   {row[column.id]}
                  // </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {rowsPerPage > 10 && ( 
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

PaginationTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

PaginationTable.defaultProps = {
  rowsPerPageOptions: [10, 25],
};

export default PaginationTable;
