import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import typographyStyles from "../../../theme/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import AdminImg from "../../../assets/profileimg-h2m.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ApiConfig from "../../../ApiConfig/ApiConfig";

const accountStyle = {
  accountContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  accountTitle: {
    ...typographyStyles.h4,
    fontWeight: "600",
    textAlign: "center",
  },
  accountContent: {
    display: "flex",
    gap: "10%",
  },
  accountInfo: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "100%",
  },
  adminImgContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
  },
  adminImg: {
    height: "12rem",
    width: "12rem",
    borderRadius: "50%",
    border: "2px solid #0D47A1", // Added a border for a professional look
  },
  adminInfoContainer: {
    display: "flex",
    gap: "10%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  accountText: {
    ...typographyStyles.h6,
    fontWeight: "600",
  },
  accountLabel: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  aboutMeContainer: {
    textAlign: "center",
    marginBottom: "20px",
    padding: "20px",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
  },
  accountSettingContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    cursor: "pointer",
  },
  accountSettingContent: {
    display: "flex",
    alignItems: "center",
    gap: "5%",
  },
  accountIcon: {
    color: "rgb(0, 152, 206)",
    fontSize: "40px",
  },
};

// const ProfileContainer = styled(Box)(({ theme }) => ({
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   backgroundColor: "#e5f6fb",
//   borderRadius: "10px",
//   // boxShadow: theme.shadows[5],
//   padding: theme.spacing(3),
//   margin: theme.spacing(2),
//   textAlign: "center",
//   color: "#333",
//   [theme.breakpoints.up("md")]: {
//     flexDirection: "row",
//     alignItems: "flex-start",
//     padding: theme.spacing(4),
//   },
// }));


const MyAccount = () => {
  const [img, setimg] = useState(null);
  const [name, setname] = useState(null);
  const [email, setemail] = useState(null);
  const Navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = sessionStorage.getItem("token");
      const userData = JSON.parse(sessionStorage.getItem("user"));
      const userId = userData.id; 

      try {
        let response = await axios({
          url: ApiConfig.getUser,
          headers: {
            token: `Bearer ${token}`,
          },
          params: {
            _id: userId,
          },
        });

        if (response.status === 200) {
          setimg(response.data.result.image);
          setname(response.data.result.userName);
          setemail(response.data.result.email);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <DashboardLayout sectionName="My Account" title="Admin Profile" showTitleOnly={true}>
      <Box sx={accountStyle.accountContainer}>

        <Box sx={accountStyle.aboutMeContainer}>
          <Typography sx={{ ...typographyStyles.h6, fontWeight: "600", marginBottom: "10px" }}>
            About Me
          </Typography>
          <Typography sx={{ marginBottom: "0" }}>
            As the admin of <strong>my-pitch</strong>, I am dedicated to fostering a vibrant community for artists and listeners alike. 
            With a passion for music and an eye for talent, my role is to ensure a seamless experience for all users. 
            I manage artist profiles, facilitate communication, and oversee listener engagement, 
            all while continuously striving to improve our platform. 
            I believe in the power of music to connect people and am committed to supporting artists in their journey. 
            Together, let's create unforgettable experiences in the world of music!
          </Typography>
        </Box>

        <Box sx={accountStyle.accountContent}>
          <Box sx={accountStyle.accountInfo}>
            <Box sx={accountStyle.adminImgContainer}>
              <Avatar
                style={accountStyle.adminImg}
                src={img == null ? AdminImg : img}
              />
            </Box>
            <Box sx={accountStyle.adminInfoContainer}>
              <Box sx={accountStyle.accountLabel}>
                <Typography sx={accountStyle.accountText}>Name:</Typography>
                <Typography sx={accountStyle.accountText}>Email Address:</Typography>
              </Box>
              <Box sx={accountStyle.accountLabel}>
                <Typography sx={accountStyle.accountText}>
                  {name == null ? "--" : name}
                </Typography>
                <Typography sx={accountStyle.accountText}>
                  {email == null ? "--" : email}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={accountStyle.accountSettingContainer}>
            <Box
              sx={accountStyle.accountSettingContent}
              onClick={() =>
                Navigate("/myaccount/editaccount", {
                  state: { name, img, email },
                })
              }
            >
              <AccountCircleIcon sx={accountStyle.accountIcon} />
              <Typography sx={accountStyle.accountText}>
                Edit Profile
              </Typography>
            </Box>
            <Box
              sx={accountStyle.accountSettingContent}
              onClick={() => {
                Navigate("/myaccount/changepassword");
              }}
            >
              <SettingsIcon sx={accountStyle.accountIcon} />
              <Typography sx={accountStyle.accountText}>
                Change Password
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default MyAccount;
