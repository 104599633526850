import { Box, Typography } from "@mui/material";
import React from "react";
import typographyStyles from "../theme/Typography"; // Import your typography styles

const footerStyle = {
  footerbox:{
    width: '100%',
    height: "60px",
    color: 'rgb(255,255,255)',
    backgroundColor: 'rgb(0, 168, 215)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    // position: 'fixed',
    // bottom: 0,
  },
  footercontent: {
    ...typographyStyles.h5, // Apply typography styles here
  },
};

const Footer = () => {
  return (
    <Box
      style={footerStyle.footerbox}
    >
      <Typography variant="h6" style={footerStyle.footercontent}>Admin panel Developed By Mobiloitte Technologies</Typography>
    </Box>
  );
};

export default Footer;
