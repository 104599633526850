import React, { useEffect, useState } from "react";
import {
  getAPIHandlerForRevenue,
  getAPIHandler,
} from "../../../ApiConfig/service";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  TextField,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CircularProgress,
  Link as MuiLink,
  styled,
  Box,
  Pagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Search as SearchIcon, Delete as ClearIcon } from "@mui/icons-material";

const useStyles = styled((theme) => ({
  artistCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    height: "400px", 
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  artistImage: {
    borderRadius: "50%",
    width: 150,
    height: 150,
    marginBottom: theme.spacing(2),
    objectFit: "cover", 
  },
  artistName: {
    marginTop: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "center",
  },
  followersText: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4),
  },
  error: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4),
    color: theme.palette.error.main,
  },
  artistGridContainer: {
    marginTop: theme.spacing(10),
  },
}));

const SearchContainer = styled("form")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#fff", 
  borderRadius: "50px", 
  padding: theme.spacing(1),
  boxShadow: theme.shadows[2],
  width: "100%",
  maxWidth: "600px",
}));

const SearchInput = styled(TextField)(({ theme }) => ({
  flexGrow: 1,
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px", 
    backgroundColor: "#e5f6fb", 
    height: "45px", 
    color: "#333", 
    paddingLeft: theme.spacing(2), 
    "& input::placeholder": {
      color: "#333", 
    },
    "& fieldset": {
      border: "none",
    },
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));



const Revenue = () => {
  const classes = useStyles();
  const [artists, setArtists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("Arijit Singh");
  const [page, setPage] = useState(1); 
  const [totalArtists, setTotalArtists] = useState(0); 
  const artistsPerPage = 12; 

  const getToken = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "getSpotifyToken",
      });

      if (response && response.data && response.data.accessToken) {
        return response.data.accessToken;
      } else {
        throw new Error("Access token not found in API response");
      }
    } catch (error) {
      console.error("Error fetching token:", error);
      throw error;
    }
  };

  const fetchArtists = async (name, page) => {
    setLoading(true);
    const token = await getToken();
    try {
      const offset = (page - 1) * artistsPerPage;
      const response = await getAPIHandlerForRevenue({
        endPoint: "getAllArtist",
        params: {
          name,
          limit: artistsPerPage.toString(),
          offset: offset.toString(),
        },
        headers: {
          access_token: token,
        },
      });

      if (response?.data?.result?.body?.artists?.items) {
        setArtists(response.data.result.body.artists.items);
        setTotalArtists(response.data.result.body.artists.total);
        setError(null);
      } else {
        setError("No artist data found");
      }
    } catch (err) {
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArtists(searchTerm, page); 
  }, [searchTerm, page]); 
  

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1); 
    fetchArtists(searchTerm, 1);
  };
  

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...'; 
    }
    return text; 
  };

  const navigate = useNavigate();

  const handleArtistClick = (spotifyId) => {
    navigate(`/ArtistListing/artistrevenue/spotify:artist:${spotifyId}`);
  };

  const handlePageChange = (event, value) => {
    setPage(value); 
    fetchArtists(searchTerm, value); 
  };
  

  return (
    <DashboardLayout sectionName="Revenue Management" title="Artist Listing" 
    showTitleOnly={true}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={8} md={6} display="flex" justifyContent="center">
          <SearchContainer onSubmit={handleSearch}>
            <IconWrapper>
              <SearchIcon style={{ color: "rgb(0, 168, 215)" }} />
            </IconWrapper>
            <SearchInput
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="What do you want to play?"
              variant="outlined"
            />
          </SearchContainer>
        </Grid>

        {loading && (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}

        <Grid item container spacing={4} margin={2}>
          {artists.map((artist) => (
            <Grid item key={artist.id} xs={12} sm={6} md={4} lg={3}>
              <Card
                className={classes.artistCard}
                onClick={() => handleArtistClick(artist.id)}
            
                sx={{
                  cursor: "pointer" ,
                  transition: '0.3s ease', 
                  boxShadow: 2,
                  '&:hover': {
                    boxShadow: 6, 
                    transform: 'translateY(-5px)', 
                  },
                }}
              >
                <CardMedia
                  component="img"
                  className={classes.artistImage}
                  image={
                    artist.images[0]?.url || "https://via.placeholder.com/150"
                  }
                  alt={artist.name}
                  sx={{
                    height: '300px', 
                    objectFit: 'cover', 
                  }}
                />
                <CardContent>
                  <Typography variant="h6" className={classes.artistName}>
                     {truncateText(artist.name, 25)}
                  </Typography>
                  <Typography variant="body2" className={classes.followersText}>
                    Followers: {artist.followers.total.toLocaleString()}
                  </Typography>
                  <MuiLink
                    href={artist.external_urls.spotify}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="hover"
                  >
                    View on Spotify
                  </MuiLink>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Grid container justifyContent="center" alignItems="center">
          <Pagination
            count={Math.ceil(totalArtists / artistsPerPage)} 
            page={page}
            onChange={handlePageChange} 
            color="primary"
            sx={{ mt: 4 }} 
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default Revenue;
