import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box } from "@mui/material";
import PaginationTable from "../../../components/Staticpaginationtable";
import ApiConfig from "../../../ApiConfig/ApiConfig";
import axios from "axios";

const staticContentStyle = {
    staticContainer: {
        marginTop: '20px',
        padding: "0 25%",
    }
}

const columns = [
    { id: 'title', label: 'Title' },
    { id: 'action', label: 'Action' },
];

const StaticContentListing = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axios({
                    method: "GET",
                    url: ApiConfig.getAllStaticContentData,
                });
              
                if (response && response?.data?.responseCode === 200) {
                    let result = response.data.result;
                    let formattedData = result.map(row => ({
                        ...row,
                        viewEndpoint: '/staticcontent/view',
                        editEndpoint: '/staticcontent/edit'
                    }));
                    setData(formattedData);
                }
            } catch (error) {
                console.error('An error occurred while fetching the data:', error);
            }
        };

        fetchData();
    }, [page, limit]);

    return (
        <DashboardLayout sectionName="Static Content Management" showBackButtonSection={false}>
            <Box sx={staticContentStyle.staticContainer}>
                <PaginationTable 
                    columns={columns} 
                    data={data.map((row) => ({
                        ...row,
                    }))}
                />
            </Box>
        </DashboardLayout>
    );
}

export default StaticContentListing;
