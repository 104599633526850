import * as React from 'react';
import Button from '@mui/material/Button';

const buttonStyle = {
    buttonContent: {
        textTransform:'none',
        backgroundColor: 'rgb(0, 168, 215)',
        color: 'rgb(255,255,255)',
        padding: '11px 18px',
        whiteSpace: 'nowrap',
        fontFamily: "'Mulish', sans-serif",
        boxShadow: '0 0 0.938rem 0 rgba(0, 0, 0, 0.2)',
        // borderRadius: "100px"
    }
}

export default function BasicButtons({ name, onClick, type, text }) {
  return (
      <Button variant="contained" 
      style={buttonStyle.buttonContent} 
      onClick={onClick} 
      type={type} 
      name={name}
      fullWidth >{text}</Button>
  );
}
