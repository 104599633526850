import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  IconButton,
  Tooltip,
  CircularProgress,
  Modal,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoMdCopy } from "react-icons/io";
import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";
import urlimg from "../../../assets/profile_pic.jpeg";
import nodatafound from "../../../assets/datanotfound.png";
import "../../../scss/main.css";
import { getAPIHandler, postAPIHandler } from "../../../ApiConfig/service";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import toast from "react-hot-toast";
import { FaReply, FaCheckCircle, FaPencilAlt } from "react-icons/fa";
import { ToastContainer } from "react-toastify";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "thumbnail", label: "Image" },
  { id: "user_name", label: "User/Artist Name" },
  { id: "spotify_id", label: "Spotify ID" },
  { id: "permissionStatus", label: "Permission Status" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "action", label: "Action" },
];

const dropdownOptionsForPermission = ["ALL", "PENDING", "GRANTED", "DENY"];

const PermissionListing = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState("ALL");

  const navigate = useNavigate();

  useEffect(() => {
    fetchPermissionData();
  }, []);

  const fetchPermissionData = async () => {
    setLoading(true);
    try {
      const response = await getAPIHandler({
        endPoint: "getAllPermissions",
      });
      console.log("API Response:", response);
      const { permissionData = [], totalPages } = response.data.result;
      setData(permissionData);
      setFilteredData(permissionData);
      setTotalPages(totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadCSV = () => {
    const dataWithoutId = filteredData.map(
      ({ _id, userId, ...rest }, index) => ({
        sr_no: index + 1,
        user_name: userId?.userName || "-",
        email_address: userId?.email || "-",
        spotify_id: userId?.spotifyId || "-",
        ...rest,
      })
    );

    const headers = Object.keys(dataWithoutId[0]);

    let csvContent = headers.join(",") + "\n";

    csvContent += dataWithoutId
      .map((row) => headers.map((header) => row[header]).join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const timestamp = format(new Date(), "yyyy-MM-dd_HH:mm");
    saveAs(blob, `permission_list_${timestamp}.csv`);
  };

  const handleFilterChange = (filterCriteria) => {
    let filtered = data;

    if (filterCriteria.searchText) {
      const searchText = filterCriteria.searchText.toLowerCase().trim();
      filtered = filtered.filter(
        (category) =>
          category.userId?.userName?.toLowerCase().includes(searchText) ||
          category.userId?.email?.toLowerCase().includes(searchText)
      );
    }

    if (filterCriteria.fromDate) {
      const fromDate = new Date(filterCriteria.fromDate);
      filtered = filtered.filter(
        (category) => new Date(category.createdAt) >= fromDate
      );
    }

    if (filterCriteria.toDate) {
      const toDate = new Date(filterCriteria.toDate);
      filtered = filtered.filter(
        (category) => new Date(category.createdAt) <= toDate
      );
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "ALL"
    ) {
      setSelectedFeedbackType(filterCriteria.selectedValue);
      filtered = filtered.filter(
        (category) => category.permission === filterCriteria.selectedValue
      );
    } else {
      setSelectedFeedbackType("ALL");
    }

    setFilteredData(filtered);
  };

  const handleCopyClick = (spotifyId) => {
    navigator.clipboard.writeText(spotifyId);
    toast.success(`Copied Spotify ID: ${spotifyId} to clipboard`);
  };

  const handlePermissionUpdate = async (row) => {
    const newPermissionStatus =
      row.permission === "PENDING"
        ? "GRANTED"
        : row.permission === "GRANTED"
        ? "DENY"
        : "GRANTED";

    try {
      const response = await postAPIHandler({
        endPoint: "updatePermission",
        dataToSend: {
          permissionId: row._id,
          permission: newPermissionStatus,
        },
      });

      if (response && response.responseCode === 200) {
        const updatedData = data.map((item) => {
          if(item._id === row._id){
            return { ...item, permission: newPermissionStatus}
          } 
          return item;
        })
        setData(updatedData)
        toast.success("Permission updated successfully");
        fetchPermissionData();
      }
    } catch (error) {
      toast.error("Failed to update permission");
    }
  };

  return (
    <DashboardLayout
      sectionName="Permission Requests"
      showBackButtonSection={false}
      rightComponent={
        <BasicButtons text="Download CSV" onClick={handleDownloadCSV} />
      }
    >
      <Box className="category-container">
        <Box className="filter-container">
          <FilterBox
            searchbarPlaceholder="Search by username/email..."
            dropdownLabel="Permission Status"
            dropdownOptions={dropdownOptionsForPermission}
            defaultSelectedValue="ALL"
            onFilterChange={handleFilterChange}
          />
        </Box>

        {loading && (
          <CircularProgress
            size={58}
            style={{ marginLeft: 550, marginTop: 300 }}
          />
        )}

        {!loading && (
          <Box className="table-container">
            {filteredData.length === 0 ? (
              <Box
                className="no-data-found"
                style={{ textAlign: "center", padding: "20px" }}
              >
                <img
                  src={nodatafound}
                  alt="No Data Found"
                  style={{ width: "350px", height: "350px", margin: "25px" }}
                />
              </Box>
            ) : (
              <PaginationTable
                columns={columns}
                data={filteredData.map((row, index) => ({
                  sr_no: index + 1,
                  thumbnail: (
                    <img
                      src={row.artistId?.images?.[0]?.url || urlimg}
                      alt="thumbnail"
                      width="65"
                      height="35"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = urlimg;
                      }}
                    />
                  ),
                  spotify_id: (
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      {row.artistId?.spotifyId || row.userId?.spotifyId || "-"}
                      <Tooltip title="Copy Spotify ID">
                        <IconButton
                          onClick={() =>
                            handleCopyClick(row.artistId?.spotifyId)
                          }
                        >
                          <IoMdCopy style={{ fontSize: "18px" }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ),
                  user_name:
                    row.artistName ||
                    row.userId?.userName ||
                    row.artistId?.artistName ||
                    "-",
                  permissionStatus: row.permission || "-",
                  created_date_time:
                    format(new Date(row.createdAt), "yyyy/MM/dd HH:mm", {
                      timeZone: "Asia/Kolkata",
                    }) || "-",
                  action: (
                    <Box className="row-button-container">
                      <FaPencilAlt
                        style={{ fontSize: "18px" }}
                        onClick={() => {
                          handlePermissionUpdate(row);
                        }}
                      />
                    </Box>
                  ),
                }))}
              />
            )}
          </Box>
        )}
      </Box>
      <ToastContainer />
    </DashboardLayout>
  );
};

export default PermissionListing;
