import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

const searchbarStyle = {
  SearchIcon: {
    color: "rgb(0, 152, 206)",
    fontSize: '30px'
  }
}

export default function Searchbar({value, onChange, placeholder}) {
  return (
    <Box
      sx={{
        width: 400,
        maxWidth: '100%',
      }}
    >
      <TextField
        fullWidth
        id="fullWidth"
        placeholder= {placeholder}
        value={value}
        onChange={(newValue) => onChange(newValue)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={searchbarStyle.SearchIcon}/>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
