import { Box, Typography } from "@mui/material";
import React from "react";
import Logo from '../assets/logo.png';

import typographyStyles from "../theme/Typography";

const headerStyle = {
  headerbox:{
    backgroundColor: 'rgb(0, 168, 215)',
    // backgroundColor: 'rgb(255,255,255)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '10px 2%',
    // position: 'fixed',
    // left: '0',
    // right: '0',
    // top: '0',
    // zIndex: "9999",
    boxShadow: '0 0 10px 0 #D9D9D9',
  },

  title:{
    ...typographyStyles.h3,
    color: 'rgb(255,255,255)',
  },

  logo: {
    width: '80px',
    height: '80px',
  },
}

const Header = () => {
  return (
    <Box sx = {headerStyle.headerbox}>
        {/* <Typography sx = {headerStyle.title}>My Pitch</Typography> */}
        <img src={Logo} alt="Logo" style = {headerStyle.logo} />
    </Box>
  );
};

export default Header;