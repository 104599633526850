import React from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Typography } from "@mui/material";
import typographyStyles from "../../../theme/Typography";
import InputField from "../../../components/InputField";
import BasicButtons from "../../../components/Button";
import { changePasswordSchema } from "../../../schema";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ApiConfig from "../../../ApiConfig/ApiConfig";
import { toast, ToastContainer } from "react-toastify";


const changePasswordStyle = {
  changePasswordContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
  },
  titleSection: {},
  changePasswordTitle: {
    ...typographyStyles.h4,
  },
  contentSection: {
    padding: "20px 25%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  inputBox: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  changePasswordLabel: {
    ...typographyStyles.h6,
  },
  btnBox: {
    display: "flex",
    justifyContent: "center",
    width: "50%",
    margin: "0 auto",
  },
};

const ChangePassword = () => {
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const Navigate = useNavigate();

  const { values, errors, handleBlur, handleChange, touched, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: changePasswordSchema,
      onSubmit: (values) => {
        fetchData(values);
      },
    });

  const handleClipboardEvents = (e) => {
    e.preventDefault();
    toast.error("Copy/Cut/Paste operations are not allowed.");
  };

  const fetchData = async (values) => {
    try {
      const response = await axios({
        method: "POST",
        url: ApiConfig.changePassword,
        headers: {
          token: sessionStorage.getItem("token"),
        },
        data: {
          currentPassword: values.oldPassword,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
        },
      });

      if (response && response.data.responseCode === 200) {
        toast.success("Password changed successfully.");
        Navigate("/myaccount");
        
      }
    } catch (error) {
      toast.error("An error occurred while changing the password.");
      console.error("An error occurred while changing the password:", error);
    }
  };

  return (
    <DashboardLayout sectionName="My Account" title="Change Password">
      <Box sx={changePasswordStyle.changePasswordContainer}>
        <form
          style={changePasswordStyle.contentSection}
          onSubmit={handleSubmit}
        >
          <Box sx={changePasswordStyle.inputBox}>
            <Typography sx={changePasswordStyle.changePasswordLabel}>
              Old Password
            </Typography>
            <InputField
           
              type="password"
              name="oldPassword"
              value={values.oldPassword}
              maxLength={16}
              onChange={handleChange}
              onBlur={handleBlur}
              onCopy={handleClipboardEvents}
              onCut={handleClipboardEvents}
              onPaste={handleClipboardEvents}
              placeholder="Enter the old password"
            />
            {errors.oldPassword && touched.oldPassword ? (
              <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                {errors.oldPassword}
              </p>
            ) : null}
          </Box>
          <Box sx={changePasswordStyle.inputBox}>
            <Typography sx={changePasswordStyle.changePasswordLabel}>
              New Password
            </Typography>
            <InputField
              type="password"
              name="newPassword"
              value={values.newPassword}
              maxLength={16}
              onChange={handleChange}
              onBlur={handleBlur}
              onCopy={handleClipboardEvents}
              onCut={handleClipboardEvents}
              onPaste={handleClipboardEvents}
              placeholder="Enter the new password"
            />
            {errors.newPassword && touched.newPassword ? (
              <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                {errors.newPassword}
              </p>
            ) : null}
          </Box>
          <Box sx={changePasswordStyle.inputBox}>
            <Typography sx={changePasswordStyle.changePasswordLabel}>
              Confirm Password
            </Typography>
            <InputField
              type="password"
              name="confirmPassword"
              value={values.confirmPassword}
              maxLength={16}
              onChange={handleChange}
              onBlur={handleBlur}
              onCopy={handleClipboardEvents}
              onCut={handleClipboardEvents}
              onPaste={handleClipboardEvents}
              placeholder="Enter the confirm password"
            />
            {errors.confirmPassword && touched.confirmPassword ? (
              <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                {errors.confirmPassword}
              </p>
            ) : null}
          </Box>
          <Box sx={changePasswordStyle.btnBox}>
            <BasicButtons text="Change Password" name="submit" type="submit" />
          </Box>
        </form>
      </Box>
      <ToastContainer />
    </DashboardLayout>
  );
};

export default ChangePassword;
