import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import typographyStyles from "../../../theme/Typography";
import { useLocation } from "react-router-dom";
import { getAPIHandlerWithToken } from "../../../ApiConfig/service";

const ViewFeedback = () => {
  const location = useLocation();
  const spotifyIdFromFeedbackListing = location.state?.spotifyId;
  const feedbackId = location.state?.feedbackId;

  const [feedback, setFeedback] = useState(null);

  const addEditStyle = {
    subTitle: {
      ...typographyStyles.h5,
      color: "black",
    },
    addEditInfo: {
      ...typographyStyles.body1,
      padding:"10px"
    },
  };

  const getFeedbackData = async () => {
    try {
      const response = await getAPIHandlerWithToken({
        endPoint: "getFeedbackBySpotifyId",
        query: { userSpotifyId: spotifyIdFromFeedbackListing },
        paramsData: { feedbackId },
      });

      const feedbackData = response.result;
      const filteredFeedback = feedbackData.filter(
        (feedback) => feedback._id === feedbackId
      );

      setFeedback(filteredFeedback.length > 0 ? filteredFeedback[0] : null);
    } catch (error) {
      console.error("Error fetching feedback:", error);
    }
  };

  useEffect(() => {
    if ((spotifyIdFromFeedbackListing, feedbackId)) {
      getFeedbackData();
    }
  }, [spotifyIdFromFeedbackListing, feedbackId]);

  return (
    <DashboardLayout sectionName="Application Feedback" title="Feedback">
      <Box sx={{ p: 2 }}>
        {feedback && (
          <Box>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h5" sx={addEditStyle.subTitle}>
                User Details
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  mb: 1,
                }}
              >
                <Typography
                  sx={{
                    ...addEditStyle.addEditInfo,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <strong>User Spotify ID :</strong>
                  <span style={{ marginLeft: "12px" }}>
                    {feedback.userSpotifyId !== null
                      ? feedback.userSpotifyId
                      : "Loading..."}
                  </span>
                </Typography>
              </Box>
            </Box>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h5" sx={addEditStyle.subTitle}>
                Feedback Details
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  mb: 1,
                }}
              >
                <Typography sx={addEditStyle.addEditInfo}>
                  <strong>Feedback Type :</strong>{" "}
                  <span style={{ marginLeft: "12px" }}>
                    {feedback.feedbackType}
                  </span>
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "2rem" }}>
                <Typography sx={addEditStyle.addEditInfo}>
                  <strong>Description :</strong>{" "}
                  <span style={{ marginLeft: "12px" }}>
                    {feedback.description}
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default ViewFeedback;
