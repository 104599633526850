export default {
    h1: {
      fontWeight: 400,
      fontSize: 50,
      fontFamily: "'Mulish', sans-serif",
      "@media(maxWidth:767px)": {
        fontSize: "35px !important",
      },
    },
    h2: {
      fontWeight: 400,
      fontSize: 42,
      fontFamily: "'Mulish', sans-serif",
      wordBreak: "break-word",
      "@media(maxWidth:767px)": {
        fontSize: "30px !important",
      },
    },
    h3: {
      fontWeight: 600,
      fontSize: 30,
      color: "#080515",
      fontFamily: "'Mulish', sans-serif",
      "@media(maxWidth:767px)": {
        fontSize: "2px !important",
      },
    },
    h4: {
      fontWeight: 700,
      fontSize: 24,
      color: "#080515",
      fontFamily: "'Mulish', sans-serif",
      "@media(maxWidth:767px)": {
        fontSize: "18px !important",
      },
    },
    h5: {
      fontWeight: 700,
      fontSize: 18,
      color: "#fff",
      lineHeight: "35px",
      fontFamily: "'Mulish', sans-serif",
      "@media(maxWidth:767px)": {
        fontSize: "12px !important",
      },
    },
    h6: {
      fontWeight: 500,
      fontSize: 16,
      color: "#080515",
      fontFamily: "'Mulish', sans-serif",
      "@media(maxWidth:767px)": {
        fontSize: "8px !important",
      },
    },
    overline: {
      fontWeight: 500,
      fontFamily: "'Mulish', sans-serif",
    },
    button: {
      textTransform: "capitalize",
      borderRadius: 27,
    },
    body2: {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: "20px",
      "@media(maxWidth:767px)": {
        fontSize: "12px",
      },
    },
    body1: {
      fontWeight: 500,
      fontSize: 14,
    },
  };
  