import * as React from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "../scss/main.css";
import { Box } from "@mui/material";

export default function ResponsiveDatePickers({ value, onChange, label }) {
  return (
    <Box
      sx={{
        width: 200,
        maxWidth: "100%",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={["DatePicker"]}
          fullWidth
          sx={{
            "& .MuiStack-root": {
              paddingTop: "9px",
            },
            "& .MuiSvgIcon-root": {
              color: "rgb(0, 152, 206) !important",
            },
          }}
        >
          <DemoItem>
            <DatePicker
              className="customDatePickerIcon"
              label={label}
              value={value}
              onChange={(newValue) => onChange(newValue)}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
}
