import React from "react";
import { Container, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Content = styled(Box)(({ theme }) => ({
  flexGrow: 1,
//   padding: theme.spacing(3),
  display: "flex",

  alignItems: "center",
  justifyContent: "center",
}));

const AuthLayout = ({ children }) => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <Content>
        <Container maxWidth="md">{children}</Container>
      </Content>
      <Footer />
    </Box>
  );
};

export default AuthLayout;
