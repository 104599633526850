import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import LoginLayout from "../../../layouts/LoginLayout/LoginLayout";
import typographyStyles from "../../../theme/Typography";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { loginSchema } from "../../../schema";
import "../../../scss/main.css";
import InputField from "../../../components/InputField";
import { postAPIHandlerWithoutToken } from "../../../ApiConfig/service";
import BasicButtons from "../../../components/Button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../../context/Auth";

const authStyle = {
  authHeading: {
    textAlign: "center",
    ...typographyStyles.h3,
  },
  authLabel: {
    ...typographyStyles.h6,
  },
  forgotPasswordLabel: {
    ...typographyStyles.h6,
    color: "#3996d2",
    cursor: "pointer",
  },
  authButton: {
    width: "50%",
    margin: "0 auto",
  }
};

const Login = () => {
  const [loading , setLoading]=useState(false)
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("password") != null
  );

  const initialValues = {
    email: localStorage.getItem("email") || "",
    password: localStorage.getItem("password") || "",
  };

  
  const navigate = useNavigate();

  const { login } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await postAPIHandlerWithoutToken({
          endPoint: "login",
          dataToSend: values,
        });

        if (response && response.data.responseCode === 200) {
          const userData = response.data.result;
          login(userData);
          toast.success(response.data.responseMessage); 
          navigate("/dashboard");

          if (rememberMe) {
            localStorage.setItem("email", values.email);
            localStorage.setItem("password", values.password);
          } else {
            localStorage.removeItem("email");
            localStorage.removeItem("password");
          }
        } else {
          if (response && response.data.responseCode === 404) {
            toast.error("Please enter correct email address."); 
          } else if (response && response.data.responseCode === 402) {
            toast.error("Please enter correct password."); 
          } else {
            toast.error("Failed to login. Please try again later.");
          }
        }
      } catch (error) {
        toast.error("Failed to login. Please try again later."); 
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <LoginLayout>
      <Box sx={{ display: "flex",
        justifyContent: "center"}}>
        <form className="authContainer" onSubmit={formik.handleSubmit}>
          <Box>
            <Typography style={authStyle.authHeading}>Admin Login</Typography>
          </Box>
          <Box className="authContent">
            <Box className="authFields">
              <Typography variant="h6" style={authStyle.authLabel}>Email</Typography>
              <InputField
                name="email"
                type="text"
                value={formik.values.email}
                maxLength={64}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter your email address"
                
              />
              {formik.errors.email && formik.touched.email ? (
                <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                  {formik.errors.email}
                </p>
              ) : null}
            </Box>

            <Box className="authFields">
              <Typography variant="h6" style={authStyle.authLabel}>
                Password
              </Typography>
              <InputField
                type="password"
                name="password"
                value={formik.values.password}
                maxLength={20}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter your password"
              />
              {formik.errors.password && formik.touched.password ? (
                <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                  {formik.errors.password}
                </p>
              ) : null}

              <Box className="checkboxField">
                <Box className="rememberMeField">
                  <input
                    type="checkbox"
                    className="rememberMe"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <label htmlFor="remember-me" style={authStyle.authLabel}>
                    Remember Me
                  </label>
                </Box>
                <Box
                  style={authStyle.forgotPasswordLabel}
                  onClick={() => {
                    navigate("/forgotpassword");
                  }}
                >
                  Forgot Password?
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="authFields" style={authStyle.authButton}>
            <BasicButtons text="Login" name="login" type="submit" loading={loading} />
          </Box>
        </form>
      </Box>
      <ToastContainer />
    </LoginLayout>
  );
};

export default Login;
