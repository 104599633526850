import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import BasicButtons from "../../../components/Button";
import FilterBox from "../../../components/FilterBox";
import PaginationTable from "../../../components/PaginationTable";
import { Box, CircularProgress } from "@mui/material";
import nodatafound from "../../../assets/datanotfound.png";
import { getAPIHandler } from "../../../ApiConfig/service";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import toast from "react-hot-toast";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "id", label: "ID" },
  { id: "title", label: "Title" },
  { id: "type", label: "Notification Type" },
  { id: "user_spotify_id", label: "User Spotify ID" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "status", label: "Status" },
];


const ArtistNotification = ({ artistSpotifyId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [totalPages, setTotalPages] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [renderStatus, setRenderStatus] = useState(false);
  const [userType, setUserType] = useState("ARTIST");
  const [selectedFeedbackType, setSelectedFeedbackType] = useState("ALL");

  useEffect(() => {
    fetchNotificationData();
  }, []);

  const fetchNotificationData = async () => {
    setLoading(true);
    try {
      const response = await getAPIHandler({
        endPoint: "getAllArtistNotification",
        paramsData: { artistSpotifyId },
      });

      const notifications = response.data.result.notifications; 
      // const totalPages = response.data.results;
      setData(notifications);
      // setFilteredData(notifications);

      // setTotalPages(totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // const handleDownloadCSV = () => {
  //   const dataWithoutId = filteredData.map(
  //     ({ _id, userId, ...rest }, index) => ({
  //       sr_no: index + 1,
  //       user_name: userId.userName || "-",
  //       email_address: userId.email || "-",
  //       spotify_id: userId.spotifyId || "-",
  //       ...rest,
  //     })
  //   );

  //   const headers = Object.keys(dataWithoutId[0]);

  //   let csvContent = headers.join(",") + "\n";

  //   csvContent += dataWithoutId
  //     .map((row) => headers.map((header) => row[header]).join(","))
  //     .join("\n");

  //   const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  //   const timestamp = format(new Date(), "yyyy-MM-dd_HH:mm");
  //   saveAs(blob, `notification_list_${timestamp}.csv`);
  // };

  // const handleFilterChange = (filterCriteria) => {
  //   let filtered = data;
  //   console.log(filterCriteria);

  //   if (filterCriteria.searchText) {
  //     const searchText = filterCriteria.searchText.toLowerCase().trim();

  //     filtered = filtered.filter(
  //       (category) =>
  //         category.userId.userName.toLowerCase().includes(searchText) ||
  //         category.userId.email.toLowerCase().includes(searchText)
  //     );
  //     console.log("filtered: ", filtered);
  //   }

  //   const parseCustomDate = (romDate) => {
  //     const year = romDate.$y;
  //     const month = romDate.$M;
  //     const day = romDate.$D;
  //     const hours = romDate.$H;
  //     const minutes = romDate.$m;
  //     const seconds = romDate.$s;
  //     return new Date(year, month, day, hours, minutes, seconds);
  //   };

  //   if (filterCriteria.fromDate) {
  //     const fromDate = parseCustomDate(filterCriteria.fromDate);
  //     console.log("fromDate:", fromDate, " (", fromDate.toISOString(), ")");

  //     filtered = filtered.filter((category) => {
  //       const createdAtDate = new Date(category.createdAt);
  //       return createdAtDate >= fromDate;
  //     });

  //     console.log("Filtered categories:", filtered);
  //   }

  //   if (filterCriteria.toDate) {
  //     const toDate = parseCustomDate(filterCriteria.toDate);
  //     console.log(toDate);

  //     filtered = filtered.filter((category) => {
  //       const createdAtDate = new Date(category.createdAt);
  //       return createdAtDate <= toDate;
  //     });

  //     console.log("Filtered categories:", filtered);
  //   }

  //   if (
  //     filterCriteria.selectedValue &&
  //     filterCriteria.selectedValue !== "ALL"
  //   ) {
  //     console.log(filterCriteria.selectedValue);
  //     setSelectedFeedbackType(filterCriteria.selectedValue);
  //     filtered = filtered.filter(
  //       (category) => category.feedbackType === filterCriteria.selectedValue
  //     );
  //   } else {
  //     setSelectedFeedbackType("ALL");
  //   }

  //   setFilteredData(filtered);
  //   console.log("Filtered Data: ", filtered);
  // };

  // const handleCopyClick = (spotifyId) => {
  //   navigator.clipboard.writeText(spotifyId);
  //   toast.success(`Copied Spotify ID: ${spotifyId} to clipboard`);
  // };

  return (
    <Box className="category-container">
      {loading && (
        <CircularProgress
          size={58}
          style={{ marginLeft: 550, marginTop: 300 }}
        />
      )}

      {!loading && (
        <Box className="table-container">
          {data.length === 0 ? (
            <Box
              className="no-data-found"
              style={{ textAlign: "center", padding: "20px" }}
            >
              <img
                src={nodatafound}
                alt="No Data Found"
                style={{ width: "350px", height: "350px", margin: "25px" }}
              />
            </Box>
          ) : (
            <PaginationTable
              columns={columns}
              data={
                data && Array.isArray(data)
                  ? data.map((row, index) => ({
                      sr_no: index + 1,
                      id: row._id, 
                      title: row.title,
                      type: row.notificationType,
                      created_date_time:
                        format(new Date(row.createdAt), "yyyy/MM/dd HH:mm", {
                          timeZone: "Asia/Kolkata",
                        }) || "-",
                      user_spotify_id: row.userSpotifyId || "-",
                      status: row.status || "-"
                    }))
                  : []
              }
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ArtistNotification;
