import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Link,
  CircularProgress,
  styled,
  Tabs,
  Tab,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import "../../../scss/main.css";
import typographyStyles from "../../../theme/Typography";
import urlImg from "../../../assets/profile_pic.jpeg";
import { FaExternalLinkAlt } from "react-icons/fa";
import Player from "../../../components/Player";
import { getAPIHandler } from "../../../ApiConfig/service";
import Notification from "../Notification/Notification";
import ArtistNotification from "../Notification/ArtistNotification";
import ActivityLogs from "./ActivityLogs";

const ProfileContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#e5f6fb",
  borderRadius: "10px",
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  textAlign: "center",
  color: "#333",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    alignItems: "flex-start",
    padding: theme.spacing(4),
  },
}));

const ChartContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
  backgroundColor: "#e5f6fb",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  overflow: "hidden",
}));

const ChartTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(2),
}));

const LineChart = styled(Box)(({ theme }) => ({
  "& .apexcharts-title-text": {
    color: theme.palette.text.primary,
  },
  "& .apexcharts-xaxis-label": {
    color: theme.palette.text.secondary,
  },
  "& .apexcharts-yaxis-label": {
    color: theme.palette.text.secondary,
  },
}));

const ProfileImage = styled(Box)(({ theme }) => ({
  width: 150,
  height: 150,
  borderRadius: "50%",
  overflow: "hidden",
  border: `4px solid rgb(0, 168, 215)`,
  marginBottom: theme.spacing(2),
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

const ProfileDetails = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
}));

const FollowButton = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1, 4),
  backgroundColor: "transparent",
  color: "#333",
  borderRadius: theme.shape.borderRadius,
  textAlign: "start",
  cursor: "pointer",
  transition: "background-color 0.3s",
}));

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "thumbnail", label: "Image" },
  { id: "user_name", label: "User Name" },
  { id: "email_address", label: "Email Address" },
  { id: "spotify_id", label: "Spotify ID" },
  { id: "feedbackType", label: "Feedback Type" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "action", label: "Action" },
];

const ViewUser = () => {
  const { id } = useParams();
  const [artist, setArtist] = useState(null);
  const [user, setUser] = useState(null);
  const [albums, setAlbums] = useState([]);
  const [topTracks, setTopTracks] = useState([]);
  const [fairPrice, setFairPrice] = useState(null);
  const [revenueEstimates, setRevenueEstimates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [totalListenersoOfArtist, setTotalListenersOfArtist] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const userID = location.state?.userId;


  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const addEditStyle = {
    addEditLabel: {
      ...typographyStyles.h5,
      color: "black",
    },
    addEditInfo: {
      ...typographyStyles.h6,
      fontWeight: "300",
    },
    albumTrackItem: {
      position: "relative",
      overflow: "hidden",
      cursor: "pointer",
      transition: "transform 0.2s, filter 0.2s",
      "&:hover": {
        transform: "scale(1.1)",
        filter: "brightness(0.8)",
      },
    },
    albumTrackImage: {
      width: "100%",
      height: "auto",
      display: "block",
    },
  };

  const formatFollowers = (count) => {
    if (count >= 1000000) {
      return (count / 1000000).toFixed(1) + "M";
    } else if (count >= 1000) {
      return (count / 1000).toFixed(1) + "k";
    } else {
      return count.toString();
    }
  };

  const fetchArtistUUID = async (id) => {
    const url = `https://customer.api.soundcharts.com/api/v2.9/artist/by-platform/spotify/${id}`;
    const headers = {
      "x-app-id": process.env.REACT_APP_SOUNDCHARTS_APP_ID,
      "x-api-key": process.env.REACT_APP_SOUNDCHARTS_API_KEY,
    };

    try {
      const response = await axios.get(url, { headers });

      if (response.data && response.data.object && response.data.object.uuid) {
        const uuid = response.data.object.uuid;
        return uuid;
      } else {
        throw new Error("UUID not found in response data");
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchTotalListeners = async (uuid) => {
    const platform = "spotify";
    const url = `https://customer.api.soundcharts.com/api/v2/artist/${uuid}/streaming/${platform}/listening`;
    const headers = {
      "x-app-id": process.env.REACT_APP_SOUNDCHARTS_APP_ID,
      "x-api-key": process.env.REACT_APP_SOUNDCHARTS_API_KEY,
    };

    try {
      const response = await axios.get(url, { headers });

      if (response.data.items && response.data.items.length > 0) {
        let maxValue = -Infinity;
        response.data.items.forEach((item) => {
          if (item.value !== undefined && typeof item.value === "number") {
            if (item.value > maxValue) {
              maxValue = item.value;
              setTotalListenersOfArtist(maxValue);
            }
          } else {
            console.warn(
              "Encountered invalid or undefined 'value' in an item:",
              item
            );
          }
        });

        if (maxValue !== -Infinity) {
          return maxValue;
        } else {
          throw new Error("No valid 'value' found in items array");
        }
      } else {
        console.error(
          "Error: Items array is empty or undefined in the response"
        );
        throw new Error("Items array is empty or undefined in the response");
      }
    } catch (error) {
      console.error("Error fetching total listeners:", error);
      throw error;
    }
  };

  const calculateFairPrice = (totalListeners) => {
    const discountRate = 0.1;
    const startYear = 2024;
    const endYear = 2029;
    const duration = endYear - startYear + 1;

    const estimatedCashFlows = [];
    let totalRevenue = 0;
    for (let i = 0; i < duration; i++) {
      const estimatedCashFlow = totalListeners * Math.pow(1 + discountRate, i);
      estimatedCashFlows.push(estimatedCashFlow);
      totalRevenue += estimatedCashFlow;
    }

    let fairPrice = 0;
    for (let i = 0; i < duration; i++) {
      fairPrice += estimatedCashFlows[i] / Math.pow(1 + discountRate, i + 1);
    }

    return {
      fairPrice: fairPrice.toFixed(2),
      totalRevenue: totalRevenue.toFixed(2),
      revenueEstimates: estimatedCashFlows.map((cashFlow, index) => ({
        year: startYear + index,
        revenue: cashFlow.toFixed(2),
      })),
    };
  };

  const getToken = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "getSpotifyToken",
      });

      if (response && response.data && response.data.accessToken) {
        return response.data.accessToken;
      } else {
        throw new Error("Access token not found in API response");
      }
    } catch (error) {
      console.error("Error fetching token:", error);
      throw error;
    }
  };

  const fetchArtistData = async (id, token) => {
    try {
      const artistResponse = await axios.get(
        `https://api.spotify.com/v1/artists/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setArtist(artistResponse.data);

      const albumsResponse = await axios.get(
        `https://api.spotify.com/v1/artists/${id}/albums`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setAlbums(albumsResponse.data.items);

      const topTracksResponse = await axios.get(
        `https://api.spotify.com/v1/artists/${id}/top-tracks`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setTopTracks(topTracksResponse.data.tracks);

      const uuid = await fetchArtistUUID(id);

      if (uuid) {
        const totalListeners = await fetchTotalListeners(uuid);

        const { fairPrice, revenueEstimates } =
          calculateFairPrice(totalListeners);
        setFairPrice(fairPrice);
        setRevenueEstimates(revenueEstimates);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching artist data:", error);
      setError(error);
      setLoading(false);
    }
  };

  const fetchUserData = async (spotifyId) => {
    try {
      const response = await getAPIHandler({
        endPoint: "getUserBySpotifyId",
        paramsData: { spotifyId },
      });
      setUser(response.data.result);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching user details:", error);
      setError(error);
      setLoading(false);
    }
  };

  const TabPanel = ({ children, value, index }) => {
    return value === index ? <Box mb={2}>{children}</Box> : null;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = await getToken();

        if (id.startsWith("spotify:artist:")) {
          const artistId = id.split(":")[2];
          await fetchArtistData(artistId, token);
        } else if (id.startsWith("spotify:user:")) {
          const userId = id.split(":")[2];
          await fetchUserData(userId);
        } else {
          setError(new Error("Invalid Spotify ID format"));
          setLoading(false);
        }
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const chartData = {
    categories: revenueEstimates.map((data) => data.year),
    series: [
      {
        name: "Revenue",
        data: revenueEstimates.map((data) => parseFloat(data.revenue)),
      },
    ],
  };

  const chartOptions = {
    series: chartData.series,
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "25%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: chartData.categories,
      title: {
        text: "Year-wise",
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: "#333",
        },
      },
    },
    yaxis: {
      title: {
        text: "Revenue ($)",
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: "#333",
        },
      },
      min: 0,
      tickAmount: 8,
      forceNiceScale: true,
      labels: {
        formatter: function (val) {
          return val === 0 ? "" : `$${Math.floor(val)}`;
        },
      },
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter: function (val) {
          return "$" + val;
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      labels: {
        colors: "#333",
        useSeriesColors: false,
      },
    },
  };

  return (
    <DashboardLayout sectionName="User Management" title="Personal Details">
      <Box className="view-user-container">
        {loading && (
          <CircularProgress
            size={58}
            style={{ marginLeft: 550, marginTop: 300 }}
          />
        )}
        {error && <Typography>Error: {error.message}</Typography>}

        {!loading &&
          !error &&
          artist &&
          revenueEstimates &&
          revenueEstimates.length > 0 && (
            <Box className="view-user-content">
            
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="user profile tabs"
                centered
              >
                <Tab label="General Info" />
                <Tab label="Notifications" />
                <Tab label="Activity Logs" />
              </Tabs>

              <TabPanel value={tabIndex} index={0}>
                <ProfileContainer>
                  <ProfileImage>
                    <img src={artist.images[1]?.url} alt="Artist" />
                  </ProfileImage>
                  <ProfileDetails>
                    <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                      {artist.name}
                    </Typography>
                    <Typography>
                      Followers: {formatFollowers(artist.followers.total)}
                    </Typography>
                    <Typography>Popularity: {artist.popularity}</Typography>
                    <Typography>
                      Total Listeners: {totalListenersoOfArtist}
                    </Typography>

                    <Typography>
                      Total Revenue Estimates: ${fairPrice}
                    </Typography>

                    {currentTrack && <Player track={currentTrack} />}
                    <FollowButton>
                      <Typography>
                        <Link
                          href={artist.external_urls.spotify}
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="none"
                          sx={{ color: "inherit", textDecoration: "none" }}
                        >
                          Spotify <FaExternalLinkAlt />
                        </Link>
                      </Typography>
                    </FollowButton>
                  </ProfileDetails>
                </ProfileContainer>

                <Box className="top-tracks-list">
                  <ChartTitle variant="h5">Best of {artist.name}</ChartTitle>
                  <Box className="top-tracks-list-content">
                    <Box className="top-tracks-list-inner">
                      {topTracks.length > 0 ? (
                        topTracks.map((track) => (
                          <Box
                            key={track.id}
                            className="track-item"
                            onClick={() => setCurrentTrack(track)}
                            sx={addEditStyle.albumTrackItem}
                          >
                            {track.album.images.length > 0 && (
                              <img
                                src={track.album.images[0].url}
                                alt={track.name}
                                className="track-image"
                                style={addEditStyle.albumTrackImage}
                              />
                            )}
                            <Typography
                              sx={addEditStyle.addEditInfo}
                              className="track-name"
                            >
                              {track.name}
                            </Typography>
                          </Box>
                        ))
                      ) : (
                        <Typography sx={addEditStyle.addEditInfo}>
                          No top tracks found
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box className="albums-list">
                  <ChartTitle variant="h5">Notable Releases</ChartTitle>
                  <Box className="albums-list-content">
                    <Box className="albums-list-inner">
                      {albums.length > 0 ? (
                        albums.map((album) => (
                          <Box
                            key={album.id}
                            className="album-item"
                            onClick={() => setCurrentTrack(album)}
                            sx={addEditStyle.albumTrackItem}
                          >
                            {album.images.length > 0 && (
                              <img
                                src={album.images[0].url}
                                alt={album.name}
                                className="album-image"
                                style={addEditStyle.albumTrackImage}
                              />
                            )}
                            <Typography
                              sx={addEditStyle.addEditInfo}
                              className="album-name"
                            >
                              {album.name}
                            </Typography>
                          </Box>
                        ))
                      ) : (
                        <Typography sx={addEditStyle.addEditInfo}>
                          No albums found
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>

                <ChartContainer>
                  <ChartTitle variant="h5">
                    Revenue Estimates Over Years
                  </ChartTitle>
                  <LineChart>
                    <ReactApexChart
                      options={chartOptions}
                      series={chartOptions.series}
                      type="bar"
                      height={350}
                    />
                  </LineChart>
                </ChartContainer>
              </TabPanel>

              <TabPanel value={tabIndex} index={1}>
                
                <Typography>
                  <ArtistNotification artistSpotifyId={artist.id} />
                </Typography>
              </TabPanel>

              <TabPanel value={tabIndex} index={2}>
                <ActivityLogs userId={userID} />
              </TabPanel>
            </Box>
          )}

        {!loading && !error && user && (
          <Box className="view-user-content">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="user profile tabs"
              centered
            >
              <Tab label="General Info" />
              <Tab label="Notifications" />
              <Tab label="Activity Logs" />
            </Tabs>

            <TabPanel value={tabIndex} index={0}>
              <ProfileContainer>
                <ProfileImage>
                  <img
                    src={
                      user.images && user.images.length > 1
                        ? user.images[1].url
                        : urlImg
                    }
                    alt={user.name}
                  />
                </ProfileImage>

                <ProfileDetails>
                  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                    {user.userName}
                  </Typography>
                  <Typography>Email: {user.email}</Typography>
                  <Typography>Country: {user.country}</Typography>

                  <FollowButton>
                    <Typography>
                      {user && (
                        <Link
                          href={`https://open.spotify.com/user/${user.spotifyId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="none"
                          sx={{
                            color: "inherit",
                            textDecoration: "none",
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          Spotify{" "}
                          <FaExternalLinkAlt style={{ marginLeft: "4px" }} />
                        </Link>
                      )}
                    </Typography>
                  </FollowButton>
                </ProfileDetails>
              </ProfileContainer>
            </TabPanel>

            <TabPanel value={tabIndex} index={1}>
              <Typography>
                <Notification userSpotifyId={user.spotifyId} />
              </Typography>
            </TabPanel>

            <TabPanel value={tabIndex} index={2}>
               <ActivityLogs userId={user._id} />
            </TabPanel>
          </Box>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default ViewUser;
