import React, {useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../../scss/main.css";
import LoginLayout from "../../../layouts/LoginLayout/LoginLayout";
import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import {
  postAPIHandlerWithoutToken,
} from "../../../ApiConfig/service";
import { toast, ToastContainer } from "react-toastify";

const authStyle = {
  authHeading: {
    textAlign: "center",
    ...typographyStyles.h3,
  },
  authSubHeading: {
    ...typographyStyles.h6,
    color: "#0009",
    textAlign: "center",
  },
  authLabel: {
    ...typographyStyles.h6,
  },
  forBack: {
    ...typographyStyles.h6,
    color: "#3996d2",
    textAlign: "center",
    cursor: "pointer",
    marginLeft: "3px",
  },
  otpInputContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "2%",
  },
  otpInput: {
    height: "70px",
    width: "12%",
    borderRadius: "10px",
    fontSize: "30px",
    textAlign: "center",
    border: "1px solid rgba(225, 225, 225, 1)",
  },
  authButton: {
    width: "50%",
    margin: "0 auto",
  }
};

const VerifyOTP = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [timer, setTimer] = useState({ minutes: 1, seconds: 0 });
  const [timerActive, setTimerActive] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [email, setEmail] = useState(location.state?.email);
  const [otpFromForgotPassword, setOtpFromForgotPassword] = useState(location.state?.otp);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleChange = (index, e) => {
    let { value } = e.target;
    if (value === "" && index > 0) {
      inputRefs[index - 1].current.focus();
    } else if (value.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpValue = inputRefs.map((ref) => ref.current.value).join("");
    if (!email) {
      console.log("Email is undefined");
      return;
    }
    if (otpValue == otpFromForgotPassword) {
      try {
        const response = await postAPIHandlerWithoutToken({
          endPoint: "verifyOTP",
          dataToSend: {
            email: email.toLocaleLowerCase(),
            otp: otpValue,
          },
        });
        console.log(response.data);
        navigate("/resetpassword", {
          replace: true,
          state: { email },
        });
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      console.log("Invalid OTP");
      toast.error("Invalid OTP.")
    }
  };
  

  useEffect(() => {
    inputRefs[0].current.focus();
  }, []);

  useEffect(() => {
    let intervalId;
    if (timerActive) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer.minutes === 0 && prevTimer.seconds === 0) {
            setTimerActive(false);
            clearInterval(intervalId);
            return { minutes: 1, seconds: 0 };
          } else if (prevTimer.seconds === 0) {
            return { minutes: prevTimer.minutes - 1, seconds: 59 };
          } else {
            return {
              minutes: prevTimer.minutes,
              seconds: prevTimer.seconds - 1,
            };
          }
        });
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [timerActive]);

  const handleResendOTP = async () => {
    setTimerActive(true);
    try {
      setIsUpdating(true);
      const response = await postAPIHandlerWithoutToken({
        endPoint: "forgotPassword",
        dataToSend: {
          email: email.toLocaleLowerCase(),
        },
      });
      if (response.data.responseCode === 200) {
        const { otp } = response.data.result;
        setOtpFromForgotPassword(otp);
        setEmail(email.toLocaleLowerCase()); 
        toast.success("OTP resent successfully!")
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      console.error("Error while resending OTP:", error);
      toast.error("Error while resending OTP")
    } finally {
      setIsUpdating(false);
    }
  };   

  return (
    <LoginLayout>
      <Box sx={{ display: "flex",
        justifyContent: "center"}}>
        <form className="authContainer" onSubmit={handleSubmit}>
          <Box className="authHeadingBox">
            <Typography style={authStyle.authHeading}>Verify OTP</Typography>
            <Typography style={authStyle.authSubHeading}>
              Please enter the 6 digits OTP sent on your registered email address.
            </Typography>
          </Box>
          <Box className="authContent">
            <Box className="authFields">
              <Typography variant="h6" style={authStyle.authLabel}>
                OTP
              </Typography>
              <Box style={authStyle.otpInputContainer}>
                {inputRefs.map((ref, index) => (
                  <input
                    key={index}
                    ref={ref}
                    style={authStyle.otpInput}
                    maxLength={1}
                    onKeyDown={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.key === "Backspace" && e.target.value === "" && index > 0) {
                        inputRefs[index - 1].current.focus();
                      }
                      if (!re.test(e.key) && e.key !== "Backspace") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => handleChange(index, e)}
                    required
                  />
                ))}
              </Box>
              {timerActive && (
                <div className="timer-container" style={{ textAlign: "end", color: "red" }}>
                  {timer.minutes < 10 ? `0${timer.minutes}` : timer.minutes}:
                  {timer.seconds < 10 ? `0${timer.seconds}` : timer.seconds}
                </div>
              )}
            </Box>
          </Box>
          <Box className="authFields" style={authStyle.authButton}>
            <p className="admin-login-msg" style={{ textAlign: "center", marginBottom : "7px" }}>
              If you don't receive any OTP ?
              {timerActive ? (
                ` Resend OTP`
              ) : (
                <Link onClick={handleResendOTP} style={authStyle.forBack}>
                  Resend OTP
                </Link>
              )}
            </p>
            <BasicButtons 
              text="Verify OTP"
              name="login"
              type="submit"
              disabled={isUpdating}
            />
          </Box>
          <Typography
            style={authStyle.forBack}
            onClick={() => navigate("/")}
          >
            Back to login
          </Typography>
        </form>
      </Box>
      <ToastContainer />
    </LoginLayout>
  );
};

export default VerifyOTP;
