import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

const ReplyPopup = ({ open, onClose, onSubmit }) => {
  const [reply, setReply] = useState("");

  useEffect(() => {
    if (open) {
      setReply("");
    }
  }, [open]);

  const handleSubmit = () => {
    onSubmit(reply);
    onClose();
  };

  const handleChange = (e) => {
    const text = e.target.value;
    if (text.length <= 250) {
      setReply(text);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <DialogTitle>Reply to Feedback</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={reply}
          onChange={handleChange}
          inputProps={{
            style: {
              fontFamily: "Arial, sans-serif",
              fontSize: "14px",
              color: "black",
            },
          }}
        />
        <Typography variant="caption">
          Character count: {reply.length}/250
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={reply.length < 5 || reply.length > 250}>
          Reply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReplyPopup;
