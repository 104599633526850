import axios from "axios";
import ApiConfig from "../ApiConfig";
import { toast } from "react-toastify";

export const postAPIHandler = async ({ endPoint, dataToSend, paramsData }) => {
  try {
    return await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const postAPIHandlerWithoutToken = async ({ endPoint, dataToSend }) => {
    try {
      return await axios({
        method: "POST",
        url: ApiConfig[endPoint],
        data: dataToSend ? dataToSend : null,
      
      });
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };
export const putAPIHandler = async ({ endPoint, dataToSend, paramsData }) => {
  try {
    return await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const deleteAPIHandler = async ({
  endPoint,
  dataToSend,
  paramsData,
}) => {
  try {
    return await axios({
      method: "DELETE",
      url: ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAPIHandler = async ({ endPoint, id, source, paramsData }) => {
  try {
    return await axios({
      method: "GET",
      url: id ? `${ApiConfig[endPoint]}/${id}` : ApiConfig[endPoint],
      params: paramsData ? paramsData : null,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      cancelToken: source ? source.token : null,
    });
  } catch (error) {
    console.log(error);
  }
};



export const getAPIHandlerForRevenue = async ({ endPoint, params = {}, headers = {}, source }) => {
  try {
    return await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      params,
      headers, 
      cancelToken: source ? source.token : null,
    });
  } catch (error) {
    console.error("API Error: ", error);
    throw error; 
  }
};


export const getAPIHandlerWithToken = async ({endPoint, query, data}) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: query,
      data: data,
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      toast.error(res?.data?.message);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};

export const patchAPIHandler = async ({ endPoint, dataToSend, paramsData }) => {
  try {
    return await axios({
      method: "PATCH",
      url: ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
